/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

    $(window).on("load", function(){

        var hash = window.location.hash;

        if(hash) {
            console.log("Jadę", $(hash).offset().top - $('header.banner').outerHeight());
            $(window).scrollTop($(hash).offset().top - $('header.banner').outerHeight());
        }
    });
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages






          /**
           * Get info about scope numbers and animate them
           */
          var t = true;
          function getScopeInfo()
          {

              if(t) {
              if($('#scope').length && $(this).scrollTop()>=$('#scope').position().top - $('#scope').height()) {
                  console.log("tutaj");

                  var easingFn = function (t, b, c, d) {
                      var ts = (t /= d) * t;
                      var tc = ts * t;
                      return b + c * (tc + -3 * ts + 3 * t);
                  }

                  var options = {
                      useEasing: true,
                      easingFn: easingFn,
                      useGrouping: true,
                      separator: ' ',
                      decimal: '.',
                  };

                  if (document.querySelector('#count-1')) {
                      var count1 = parseInt(document.querySelector('#count-1').dataset.count);
                      var numAnim1 = new CountUp("count-1", 0, count1,0,4,options);
                      if (!numAnim1.error) {
                          numAnim1.start();
                      } else {
                          console.error(numAnim1.error);
                      }
                  }


                  if (document.querySelector('#count-2')) {
                      var count2 = parseInt(document.querySelector('#count-2').dataset.count);
                      var numAnim2 = new CountUp("count-2", 0, count2,0,4,options);
                      if (!numAnim2.error) {
                          numAnim2.start();
                      } else {
                          console.error(numAnim2.error);
                      }
                  }


                  if (document.querySelector('#count-3')) {
                      var count3 = parseInt(document.querySelector('#count-3').dataset.count);
                      var numAnim3 = new CountUp("count-3", 0, count3,0,4,options);
                      if (!numAnim3.error) {
                          numAnim3.start();
                      } else {
                          console.error(numAnim3.error);
                      }
                  }
                  t = false;
              }

              }
          }

          $(window).on("scroll", function(){
              getScopeInfo();
          });






          var admin = $("#wpadminbar").length == 1 ? 32 : 0;
          console.log(admin);

          $(".compensate").height($("header.banner").outerHeight() - admin);

          $(window).on("resize", function(){
              $(".compensate").height($("header.banner").outerHeight()- admin);
              searchBtn();
          });

          $('.mainNav__icon-menu').on("click", function(){

              $('.mainNav__menus').toggleClass("toggle__menu");

          });


          var clicked = false;
          function searchBtn() {
              if($(window).width() > 1200) {
                  $('#searchButton').on("click", function(e){

                    if(!clicked)
                    {
                      e.preventDefault();
                      clicked = true;
                    } else {
                      clicked = false;
                    }


                      console.log("Button");
                      $('.search-field').fadeIn(200);
                      var self = this;
                      setTimeout(function(){
                        $(self).removeClass("form-active");

                          var curElement = document.activeElement;
                          console.log(curElement.className);
                          if(curElement.className != "search-field") {
                              $('.search-field').fadeOut(200);
                          }
                        clicked = false;
                      }, 5000);
                    $(this).addClass("form-active");
                  });
              }

          }
          searchBtn();



          $('.bttn--once').on("click", function(e){
              //e.preventDefault();
             //alert("Proszę wybrać kwotę wsparcia");
          });



          $('.support__amount').on("click", function(){

              $('.support__amount').not($(this)).removeClass("support__active");
              $('.support__amount').find('input[type=radio]').prop("checked", false);

              $(this).toggleClass("support__active");

              var radio = $(this).find('input[type=radio]');

              if(radio.length)
              {
                  radio.prop("checked", true);
                  $('.bttn--once').attr("title", "");
                  $('.bttn--once').removeClass('bttn--once');

              }
          });


          var formSupport = document.querySelector('.support__form__amount');

          if(formSupport) {

              $('.support__form__amount').find('input[type=number]').on("focus", function(){
                  console.log("Right");
                  $('.bttn--once').attr("title", "");
                  $('.bttn--once').removeClass('bttn--once');
              });

              formSupport.addEventListener("submit", function (e) {

                  e.preventDefault();

                  var valid = true;

                  var inputs = formSupport.querySelectorAll('input[type=radio]');

                  var inputValid = false;

                  for (var i = 0; i < inputs.length; i++) {
                      if (inputs[i].checked) {
                          inputValid = true;
                      }
                  }

                  if (inputValid || formSupport.querySelector('input[type=number]').value !== "") {
                      formSupport.submit();
                  } else {
                      alert("Wybierz kwotę wsparcia");
                      document.querySelector('.alert.alert__payment').innerText = "*Wybierz kwotę wsparcia";
                  }


              });
          }

          var supportConfirm = document.querySelector(".support__confirm");
          if(supportConfirm) {

              supportConfirm.addEventListener("submit", function (e) {
                  e.preventDefault();
                  console.log("oko");

                  var pname = document.querySelector("#payu_name").value;
                  var plastName = document.querySelector("#payu_lastName").value;
                  var pemail = document.querySelector("#payu_email").value;
                  var pphone = document.querySelector("#payu_phone").value;
                  var pagree = document.querySelector("#agree1").checked;

                  if (pname && plastName && pemail && pphone && pagree) {
                      supportConfirm.submit();

                  } else {
                      console.log("Działamssss");
                      $('.validity').empty();
                      if (pname.length <= 0) {
                          var p = document.createElement("p");
                          p.classList.add("alert");
                          p.innerText = "Dodaj imię";
                          document.querySelector('.validity').appendChild(p);
                      }
                      if (plastName.length <= 0) {
                          var p = document.createElement("p");
                          p.classList.add("alert");
                          p.innerText = "Dodaj nazwisko";
                          document.querySelector('.validity').appendChild(p);
                      }
                      if (pemail.length <= 0) {
                          var p = document.createElement("p");
                          p.classList.add("alert");
                          p.innerText = "Dodaj email";
                          document.querySelector('.validity').appendChild(p);
                      }
                      if (pphone.length <= 0) {
                          var p = document.createElement("p");
                          p.classList.add("alert");
                          p.innerText = "Dodaj nr telefonu";
                          document.querySelector('.validity').appendChild(p);
                      }if (!pagree) {
                          var p = document.createElement("p");
                          p.classList.add("alert");
                          p.innerText = "Musisz wyrazić zgodę na przetwarzanie danych osobowych";
                          document.querySelector('.validity').appendChild(p);
                      }
                  }


              });
          }

          $('.menu-item-has-children > a').on("click", function(e) {
              e.preventDefault();
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if(document.querySelector('.share__dialogs__wrap')) {

          var twitter = document.querySelector('.share__dialogs__twitter');



          var t = document.querySelector('meta[name="twitter:title"]') ? document.querySelector('meta[name="twitter:title"]').getAttribute('content') : "Miłość Nie Wyklucza";
          var url = window.location.href;

          twitter.setAttribute('href', 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(t));

        }
      }
    },
      'wspieram': {
        init: function(){
            $('.choose__amount.choose__amount--second.pay__show').on("click", function(){
                console.log("All right");
                alert("Kliknij \"Pomagam jednorazowo\"");
            });
            $('.choose__amount.choose__amount--first').on("click", function() {
                $(this).toggleClass('pay__show');
                $(this).toggleClass('order__show');

                $('.choose__amount.choose__amount--second').toggleClass('pay__show').promise().done(function(){
                    $('.choose__amount.choose__amount--second').toggleClass('order__show');
                });

                $('.support__confirm').toggleClass('pay__show').promise().done(function(){
                    $('.support__confirm').toggleClass('order__show');
                });
                $('.support__form__amount').toggleClass('pay__show').promise().done(function(){
                    $('.support__form__amount').toggleClass('order__show');
                });

            });

            $('.choose__amount.choose__amount--second').on("click", function() {

                alert("Proszę kliknąć \"Pomagam jednorazowo\" przed realizacją 2. kroku");

                // $(this).toggleClass('pay__show');
                // $(this).toggleClass('order__show');
                //
                // $('.choose__amount.choose__amount--first').toggleClass('pay__show').promise().done(function(){
                //     $('.choose__amount.choose__amount--first').toggleClass('order__show');
                // });
                //
                // $('.support__confirm').toggleClass('pay__show').promise().done(function(){
                //     $('.support__confirm').toggleClass('order__show');
                // });
                // $('.support__form__amount').toggleClass('pay__show').promise().done(function(){
                //     $('.support__form__amount').toggleClass('order__show');
                // });

            });
        }
      },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
          $('.slick__slider').slick({
              dots: false,
              arrows: false,
              infinite: true,
              speed: 300,
              slidesToShow: 8,
              //centerMode: true,
              variableWidth: true,
              autoplay: true,
              autoplaySpeed: 2000
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
      'o_nas': {
          init: function() {
              // JavaScript to be fired on the home page
              $('.slick__slider').slick({
                  dots: false,
                  arrows: false,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 8,
                  //centerMode: true,
                  variableWidth: true,
                  autoplay: true,
                  autoplaySpeed: 2000
              });
          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      },
      'single': {
          init: function() {
              // JavaScript to be fired on the home page
              console.log("Działam");

              $('.toggle button').on("click", function(){


                $text = "Zwiń -";
                console.log("Działam");

                if($(this).text() === "Rozwiń +")
                {
                    $(this).text($text);
                    $(this).parent().next().slideDown();
                } else {
                    $(this).text("Rozwiń +");
                    $(this).parent().next().slideUp();
                }

              });



          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      },
      'page': {
          init: function() {
              // JavaScript to be fired on the home page
              console.log("Działam");

              $('.toggle button').on("click", function(){
                  $text = "Zwiń -";
                  console.log("Działam");

                  if($(this).text() === "Rozwiń +")
                  {
                      $(this).text($text);
                      $(this).parent().next().slideDown();
                  } else {
                      $(this).text("Rozwiń +");
                      $(this).parent().next().slideUp();
                  }

              });

          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
